import { createReducer, on } from '@ngrx/store';
import { Tab } from '../../core/models/tab';
import { closeTab, newTab, selectTab, updateTab } from './tab.actions';

export interface TabState {
    tabs: Tab[];
    selectedTab: number;
}

export const initialState: TabState = {
    tabs: [],
    selectedTab: 0
};

export const tabReducer = createReducer(
  initialState,
  on(newTab, (state: TabState, {tab}) => {
    const existingTab = state.tabs.find(tb =>
      tb.typeTab === tab.typeTab &&
      (!tab.args?.entity && !tb.args?.entity ||
        tab.args?.entity?.id === tb.args?.entity?.id)
    );
    if (existingTab) {
      return { ...state, selectedTab: existingTab.id };
    }
    return { ...state, tabs: [...state.tabs, tab], selectedTab: tab.id };
  }),

  on(updateTab, (state: TabState, tab) => {
    const index = state.tabs.findIndex(tb => tb.args?.entity?.id === tab.args?.entity?.id);
    if (index === -1) return state;

    const newTabs = [...state.tabs];
    newTabs[index] = tab;

    return { ...state, tabs: newTabs };
  }),

  on(selectTab, (state: TabState, {index}) => ({ ...state, selectedTab: index })),

  on(closeTab, (state: TabState, tab: Tab) => {
    const newTabs = state.tabs.filter(t => t.id !== tab.id); // Удаляем вкладку по id
    return {
      ...state,
      tabs: newTabs,
      selectedTab: newTabs.length ? newTabs[newTabs.length - 1].id : 0 // Если есть вкладки, выбираем последнюю
    };
  })
);

