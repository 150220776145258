import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, of, takeWhile, timer } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../../service/auth.service';
import { Router } from '@angular/router';
import {
  activateTimer,
  AuthActionTypes,
  authSuccess, canResend,
  check,
  codeVerify,
  login,
  logout,
  resendCode, restoreSavedData, setPassword,
  signup, updateRemainingTime
} from './auth.actions';
import { openMessage } from '../message/message.actions';
import { Store } from "@ngrx/store";
import { EmailOrPhone, PasswordForm, SigninForm, SignupForm, VerificationForm } from "../../login-screen/forms";
import { MessageType } from "../../models/message";
import { isPhone } from "../../login-screen/validators";
import { ApiError } from "../../service/base.service";

@Injectable()
export class AuthEffects {

  constructor (private actions$: Actions,
               private sessionService: AuthService,
               private router: Router,
               private store: Store) {
  }

  check: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(check),
      switchMap(() => {
        const expiration = localStorage.getItem("expiration");
        if (!expiration) {
          localStorage.removeItem('accessToken');
          return EMPTY;
        }

        const exp = Number.parseInt(expiration);
        if (exp < Date.now()) {
          return this.sessionService.token().pipe(
            map(data => authSuccess(data)),
            catchError(() => {
              localStorage.removeItem('accessToken');
              return EMPTY;
            })
          );
        }

        const accessToken = localStorage.getItem("accessToken") || '';
        return of(authSuccess({ token: accessToken }));
      })
    )
  );

  login: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      switchMap((payload: SigninForm) =>
        this.sessionService.login(payload).pipe(
          map((data) => authSuccess(data)),
          tap(data => {
            localStorage.setItem('login', payload.emailOrPhone);
            this.updateToken(data.token);
            this.router.navigateByUrl('/app');
          })))));

  authSuccess: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(authSuccess),
      tap((data: any) => {
        this.updateToken(data.token);
        this.router.navigateByUrl('/app');
      })
    ), { dispatch: false }
  );

  logout: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      switchMap(() =>
        this.sessionService.logout().pipe(
          tap(() => {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('expiration');
            this.router.navigateByUrl('/signin');
          }),
          catchError(() => EMPTY)
        )
      )
    ), { dispatch: false }
  );

  signup: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(signup),
      switchMap((payload: SignupForm) =>
        this.sessionService.signup(payload).pipe(
          map(() => {
            const { email, phone } = payload;
            const hasEmail = email.length > 0;
            const hasPhone = phone.length > 0;

            if (hasEmail)
              this.saveCode(email);

            if (hasPhone)
              this.saveCode(phone);

            const text = hasEmail && hasPhone
              ? 'На указанные вами email и номер отправлены коды подтверждения'
              : hasPhone
                ? 'На указанный вами номер отправлен код подтверждения'
                : 'На указанный вами email отправлен код подтверждения';

            this.router.navigateByUrl('/confirmation');

            return openMessage({
              text: `Аккаунт успешно создан\n${text}`,
              messageType: MessageType.INFO
            });
          })))));


  codeVerify: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(codeVerify),
      switchMap((payload: VerificationForm) => {
        return this.sessionService.verifyCode(payload).pipe(
          switchMap((data: any) => {
            const isPhoneVal = isPhone(payload.emailOrPhone);

            if (isPhoneVal && (localStorage.getItem("email") || '').length > 0)
              this.router.navigate([], { queryParams: { type: 'email' } });
             else
              this.router.navigateByUrl('/signin');

            return of(openMessage({
              text: isPhoneVal ? 'Ваш телефон успешно подтвержден' : 'Ваш email успешно подтвержден',
              messageType: MessageType.INFO
            }));
          }),
        );
      })
    ));

  resendCode: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(resendCode),
      switchMap((payload: EmailOrPhone) => {
        return this.sessionService.sendCode(payload).pipe(
          switchMap(() => {
            this.saveCode(payload.emailOrPhone);

            return of(
              this.createSuccessMessage(payload),
              activateTimer({time: this.getCodeSentTime()})
            );
          }),
          catchError((error: ApiError) => {
            if (error.type === 'TOO_EARLY'){
              this.saveCode(payload.emailOrPhone);
              return of(
                this.createTooEarlyMessage(error),
                activateTimer({time: this.getCodeSentTime()})
              );
            } else
              this.sessionService.handleError(error);
            return EMPTY;
          })
        );
      })
    )
  );

  activateTimer: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(activateTimer),
      switchMap((period) =>
        timer(0, 1000).pipe(
          map(n => Math.max(period.time - n * 1000, 0)),
          takeWhile(n => n > 0, true),
          map(time => updateRemainingTime({time}))
        )
      )
    )
  );

  updateRemainingTime: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRemainingTime),
      switchMap((payload) => {
        if(payload.time === 0)
          return of(canResend({canResend: true}));
        return EMPTY;
      })
    )
  );

  setPassword: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(setPassword),
      switchMap((payload: PasswordForm) => {
        return this.sessionService.setPassword(payload).pipe(
          switchMap(() => {
            localStorage.removeItem('phoneCodeCanSend');
            localStorage.removeItem('emailCodeCanSend');
            this.router.navigateByUrl("/signin");
            return of(
              openMessage({ text: `Ваш пароль успешно изменен`, messageType: MessageType.INFO }),
              logout()
            )
          })
        );
      })));

  restoreSavedData: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(restoreSavedData),
      switchMap(() => {
        let time = this.getCodeSentTime();
        return of(
          canResend({ canResend: time === 0 }),
          activateTimer({time})
        );
      })));

  private saveCode(value: string): void {
    const isPhoneVal = isPhone(value);
    const key = isPhoneVal ? 'phoneCodeCanSend' : 'emailCodeCanSend';
    const date = Date.now() + (isPhoneVal ? 5 : 1) * 60000;
    localStorage.setItem('login', value);
    localStorage.setItem(isPhoneVal ? 'phone' : 'email', value);
    localStorage.setItem(key, date.toString());
  }

  private getCodeSentTime(): number {
    const key =  isPhone(localStorage.getItem( 'login' ) || '')
      ? 'phoneCodeCanSend'
      : 'emailCodeCanSend';

    let date = Number.parseInt(localStorage.getItem(key) || '0');
    let time = date - Date.now();
    time =  time < 0 ? 0 : time;
    return time;
  }

  private createSuccessMessage(payload: EmailOrPhone) {
    const target = isPhone(payload.emailOrPhone) ? 'телефон' : 'email';
    return openMessage({
      text: `На ваш ${target} отправлен код`,
      messageType: MessageType.INFO
    });
  }

  private createTooEarlyMessage(error: ApiError) {
    const time = Object.keys(error.info?.[0] || [])[0];
    return openMessage({
      text: `Переотправить код можно только через ${time} минут`,
      messageType: MessageType.INFO
    });
  }

  private updateToken (token: string) {
    localStorage.setItem('accessToken', token);
    const exp = JSON.parse(atob(token.split('.')[1])).exp * 1000;
    const refreshTime = exp - Date.now() - 5 * 60 * 1000;
    localStorage.setItem('expiration', refreshTime.toString());
    setTimeout(() => this.store.dispatch({ type: AuthActionTypes.CHECK }), refreshTime);
  }

}
