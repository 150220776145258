import { createReducer, on } from '@ngrx/store';
import { User } from '../../models/entity/user';
import {
  authSuccess,
  logout, canResend, updateRemainingTime, activateTimer,
} from './auth.actions';

export interface AuthState {
  isAuthenticated: boolean;
  canResend: boolean;
  remainingTime: number;
  user: User | null;
}

export const initialState: AuthState = {
  isAuthenticated: false,
  canResend: true,
  user: null,
  remainingTime: 0
};

export const authReducer = createReducer(
  initialState,
  on( authSuccess, (state: AuthState, data: any) => ({
    ...state,
    isAuthenticated: true,
    user: (data.user as User)
  })),

  on( logout, () => ({
    ...initialState
  })),

  on( canResend, (state: AuthState, { canResend }) => ({
    ...state,
    canResend: canResend
  })),

  on( updateRemainingTime, (state, { time }) => ({
    ...state,
    remainingTime: time
  })),

  on(activateTimer, (state,  {time}) => ({
    ...state,
    remainingTime: time,
    canResend: false
  }))
);


